*,
::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 1em;
}

html {
  font-size: 62.5%;
  background-size: cover;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

.Toastify__toast {
  border-radius: 0.4rem;
}

.Toastify__toast-body {
  font-size: 1.4rem;
  font-weight: 600;
}

.Toastify__toast--success {
  background-color: #60cc4a;
}
.Toastify__toast--warning {
  background-color: #e5cf50;
}
.Toastify__toast--error {
  background-color: #ed4d4e;
}

.Toastify__toast-body {
  box-shadow: none;
}
